import { em } from '@mantine/core';
import { breakPoints } from 'shared/model/common';

export const isLargeWidthThanBreakPoint = (
  width: number,
  breakpointName: string
): boolean => {
  return (
    +em(width).replace('em', '') >
    +breakPoints[breakpointName as keyof typeof breakPoints].replace('em', '')
  );
};

export const compareWithAndBreakPoint = (
  compare: (width: number, breakpoint: number) => boolean,
  width: any,
  breakpointName: any
) => {
  return compare(
    +em(width).replace('em', ''),
    +breakPoints[breakpointName as keyof typeof breakPoints].replace('em', '')
  );
};
