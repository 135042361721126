import ThemeProvider from 'app/providers/theme-provider';
import { lazy, Suspense } from 'react';

const RouterProvider = lazy(() => import('app/providers/router-provider'));

const RootProvider = () => {
  return (
    <ThemeProvider>
      <Suspense>
        <RouterProvider />
      </Suspense>
    </ThemeProvider>
  );
};

export default RootProvider;
