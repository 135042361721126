import { MantineProvider } from '@mantine/core';
import { PropsWithChildren, useMemo } from 'react';
// eslint-disable-next-line import/no-internal-modules
import '@mantine/core/styles.css';
import { useViewportSize } from '@mantine/hooks';
import '@mantine/notifications/styles.css';
import { isLargeWidthThanBreakPoint } from 'shared/lib/widths-compare';
// eslint-disable-next-line import/no-internal-modules
import { Notifications } from '@mantine/notifications';
// eslint-disable-next-line import/no-internal-modules
import 'shared/styles/index.css';
import { getCustomTheme } from './theme';

const ThemeProvider = ({ children }: PropsWithChildren) => {
  const { width } = useViewportSize();
  const theme = useMemo(
    () =>
      getCustomTheme(
        // colorTheme,
        'light',
        isLargeWidthThanBreakPoint(width, 'md') ? 'desktop' : 'mobile'
      ),
    [width]
  );

  return (
    <MantineProvider theme={theme} defaultColorScheme='light'>
      <Notifications />
      {children}
      <Notifications />
    </MantineProvider>
  );
};

export default ThemeProvider;
