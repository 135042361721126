import type { MantineThemeOverride } from '@mantine/core';
import { breakPoints } from 'shared/model/common';

export const getCustomTheme = (
  themeColor: 'light' | 'dark',
  screenSize: 'mobile' | 'laptop' | 'desktop'
) => {
  return {
    white: '#FFF',
    components: {
      Paper: {
        defaultProps: {
          radius: screenSize === 'mobile' ? 12 : 16,
          p: screenSize === 'mobile' ? 20 : 40,
          shadow: '',
        },
      },
      Button: {
        defaultProps: {
          size: 'lg',
          color: 'brand',
          fz: 14,
          fw: 600,
          loaderProps: { type: 'dots' },
        },
      },
    },
    black: 'rgba(43, 43, 43, 1)',
    colorScheme: themeColor,
    loader: 'bars',
    primaryShade: 6,
    fontFamily: 'Inter',
    colors: {
      background: [
        'rgba(243, 245, 249, 1)',
        'rgba(243, 245, 249, 1)',
        'rgba(243, 245, 249, 1)',
        'rgba(243, 245, 249, 1)',
        'rgba(243, 245, 249, 1)',
        'rgba(243, 245, 249, 1)',
        'rgba(243, 245, 249, 1)',
        'rgba(243, 245, 249, 1)',
        'rgba(243, 245, 249, 1)',
        'rgba(243, 245, 249, 1)',
      ],
      brand: [
        'hsl(253, 88%, 90%)', // -66%
        'hsl(253, 88%, 85%)', // -66%
        'hsl(253, 88%, 80%)', // -66%
        'hsl(253, 88%, 75%)', // -33%
        'hsl(253, 88%, 70%)', // -66%
        'hsl(253, 88%, 65%)', // -33%
        'hsl(253, 88%, 60%)', // +0%
        'hsl(253, 88%, 55%)', // +25%
        'hsl(253, 88%, 50%)', // +50%
        'hsl(253, 88%, 45%)', // +75%
      ],
      red: [
        '#FCDADA',
        '#FAC7C7',
        '#F9B4B4',
        '#F7A1A1',
        '#F58F8F',
        '#F47C7C',
        '#F26969',
        '#F15757',
        '#EF4444',
        '#DF4343',
      ],
      green: [
        '#CFF1E6',
        '#B7EAD9',
        '#9FE3CD',
        '#87DCC0',
        '#70D5B3',
        '#58CEA7',
        '#40C79A',
        '#28C08E',
        '#10B981',
        '#21A87B',
      ],
      fadedGray: [
        '#F6F6F6',
        '#AAAAAA',
        '#AAAAAA',
        '#AAAAAA',
        '#AAAAAA',
        '#AAAAAA',
        '#AAAAAA',
        '#AAAAAA',
        '#AAAAAA',
        '#AAAAAA',
      ],
      nativeDark: [
        '#25262b',
        '#25262b',
        '#25262b',
        '#25262b',
        '#25262b',
        '#25262b',
        '#25262b',
        '#25262b',
        '#25262b',
        '#25262b',
      ],
      dark: [
        '#FFFFFF',
        '#A6A7AB',
        '#909296',
        '#5C5F66',
        '#373A40',
        '#2C2E33',
        '#25262B',
        '#1A1B1E',
        '#141517',
        '#101113',
      ],
    },
    breakpoints: breakPoints,
    primaryColor: 'brand',
  } as MantineThemeOverride;
};
